import { LazyImg } from 'widget/lazyImg'
import css from './index.module.scss'
import pic from '@asset/0001-手机-首页-切图/手机-加入我们@2x.png'
import { NavLink } from 'react-router-dom'
import { goTop } from 'widget/goTop'
import { useTranslation } from 'react-i18next'

export const JoinUs = () => {
    const { t } = useTranslation()
    return <div className={css['join-us']}>
        <h3>{t('加入我们')}</h3>
        <LazyImg src={pic} alt=""/>
        <NavLink to={'/about-us'} onClick={goTop}>
            <div className={css.btn}>{t('招聘岗位')}</div>
        </NavLink>
    </div>
}