import { Block } from "TechnologyPage/Block";
import pic2 from '@asset/0002-手机-核心技术-切图/感知/Mapfree红绿灯检测识别@2x.png'
import video1 from '@asset/0002-手机-核心技术-切图/感知/BEV环视视觉模型.mp4'
import pic3 from '@asset/0002-手机-核心技术-切图/感知/感知地图自动更新@2x.png'
import picTitle from '@asset/0002-手机-核心技术-切图/感知/感知头部光圈@2x.png'
import picTitleCar from '@asset/0002-手机-核心技术-切图/感知/感知头部小车@2x.png'
import css from './index.module.scss'
import { LazyImg } from "widget/lazyImg";
import poster from './BEV环视视觉模型-封面.png'

const items = [
    {
        title: 'BEV环视视觉模型',
        msg: '多个相机的联合视野覆盖车周身360°，经过多个相机数据输入并进行同步对齐，共同参与深度信息的估计。',
        video: video1,
        poster
    },
    {title: 'Mapfree红绿灯检测识别', msg: '去除对高精度地图的依赖, 利用视觉模型从图像中直接识别出红绿灯的位置, 颜色和指向等信息。', pic: pic2},
    {title: '感知地图自动更新', msg: '将实时检测的背景障碍物信息在时空对齐后自动更新到感知地图当中，全过程不需要人为参与。', pic: pic3},
]


export const Perception = () => <Block
    title={'感知模块'}
    desc={'随着3D感知与时序学习等技术的突破，白犀牛自动驾驶的感知模块能力边界也在不断扩大，已拓展到交通道路结构、场景的感知以及交通参与者的行为预测。为车辆后续的规划控制提供更加精确、可靠、安全的感知信息。'}
    items={items}
>
    <div className={css['perception-bg']}>
        <LazyImg src={picTitle} alt="" className={css['pic-title']}/>
        <LazyImg src={picTitleCar} alt="" className={css['pic-title-car']}/>
    </div>
</Block>