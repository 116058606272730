import { Explode } from 'widget/explode'
import css from './index.module.scss'
import { useTranslation } from 'react-i18next'

export const Pic = () => {
    const { t } = useTranslation()
    return <div className={css.pic}>
        <h1>{t('核心技术')}</h1>
        <div className={css.explode}>
            <Explode />
        </div>
    </div>
}