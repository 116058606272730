import { LazyImg } from 'widget/lazyImg'
import css from './index.module.scss'
import pic from '@asset/0003-手机-服务中心/服务中心头图@2x.png'
import { useTranslation } from 'react-i18next'

export const ServiceBanner = () => {
    const { t } = useTranslation()
    return <div style={{fontSize: 0}}>
        <div className={css['service-banner']}>
            <h3>{t('服务中心')}</h3>
            <div className={css.desc}>{t('我们提供城市物流领域先进的自动驾驶产品和综合解决方案，可满足货运、配送和其它城市服务中多样化且无处不在的自动驾驶需求。')}</div>
        </div>
        <LazyImg src={pic} alt="" className={css['service-img']} placeholder={<div className={css['service-img-wrap']}/>}/>
    </div>
}