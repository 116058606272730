import css from './index.module.scss'
import p01 from '@asset/0005-手机-关于我们/企业荣誉/001@2x.png'
import p02 from '@asset/0005-手机-关于我们/企业荣誉/002@2x.png'
import p03 from '@asset/0005-手机-关于我们/企业荣誉/003@2x.png'
import p04 from '@asset/0005-手机-关于我们/企业荣誉/004@2x.png'
import p05 from '@asset/0005-手机-关于我们/企业荣誉/005@2x.png'
import p06 from '@asset/0005-手机-关于我们/企业荣誉/006@2x.png'
import p07 from '@asset/0005-手机-关于我们/企业荣誉/007@2x.png'
import p08 from '@asset/0005-手机-关于我们/企业荣誉/008@2x.png'
import p09 from '@asset/0005-手机-关于我们/企业荣誉/009@2x.png'
import p10 from '@asset/0005-手机-关于我们/企业荣誉/010@2x.png'
import p11 from '@asset/0005-手机-关于我们/企业荣誉/011@2x.png'
import p12 from '@asset/0005-手机-关于我们/企业荣誉/0012@2x.png'
import p13 from '@asset/0005-手机-关于我们/企业荣誉/013@2x.png'
import p14 from '@asset/0005-手机-关于我们/企业荣誉/014@2x.png'
import p15 from '@asset/0005-手机-关于我们/企业荣誉/015@2x.png'
import p16 from '@asset/0005-手机-关于我们/企业荣誉/016@2x.png'
import p17 from '@asset/0005-手机-关于我们/企业荣誉/017@2x.png'
import p18 from '@asset/0005-手机-关于我们/企业荣誉/018@2x.png'
import p19 from '@asset/0005-手机-关于我们/企业荣誉/019@2x.png'
import p20 from '@asset/0005-手机-关于我们/企业荣誉/020@2x.png'
import p21 from '@asset/0005-手机-关于我们/企业荣誉/021@2x.png'
import p22 from '@asset/0005-手机-关于我们/企业荣誉/022@2x.png'
import p23 from '@asset/0005-手机-关于我们/企业荣誉/023@2x.png'
import p24 from '@asset/0005-手机-关于我们/企业荣誉/024@2x.png'
import { useState } from 'react'
import { useDebounceFn, useDeepCompareEffect, useInterval, useMemoizedFn } from 'ahooks'
import { animated, useSpring } from '@react-spring/web'
import { useSwipeable } from 'react-swipeable'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useIsEn } from 'widget/hook/useIsEn.hook'
import iconPrev from '@asset/2023手机端官网-icon-svg/left-btn-blue.svg'
import iconNext from '@asset/2023手机端官网-icon-svg/right-btn-blue.svg'

const list = [
    { title: '国家高新技术企业', pic: p01 },
    { title: '中关村高新技术企业', pic: p02 },
    { title: '中关村金种子企业', pic: p03 },
    { title: '北京市“专精特新”中小企业', pic: p04 },
    { title: 'ISO 9001 质量管理体系认证', pic: p05 },
    { title: '中国道路交通安全协会会员', pic: p06 },
    { title: '深圳市智能交通行业协会常务理事单位', pic: p07 },
    { title: '团体标准《低速无人车城市商业运营安全管理规范》主要参编单位', pic: p08 },
    { title: '低速无人驾驶产业联盟理事单位', pic: p09 },
    { title: '2022年：上榜2022年度首批上海市智能网联汽车示范应用创新项目（揭榜挂帅）', pic: p10 },
    { title: '2022年度：安徽省级智能网联汽车创新场景示范奖', pic: p11 },
    { title: '合肥市首批低速无人车商业运营许可', pic: p12 },
    { title: '阳泉市智能网联汽车自动驾驶出行商业化试点许可', pic: p13 },
    { title: '2022同城即时物流技术创新案例', pic: p14 },
    { title: '新冠肺炎疫情防控突出贡献证书（由武汉东湖新技术开发区光谷科技会展中心方舱医院临时委员会颁发）', pic: p15 },
    { title: '2021年：中关村5G创新应用大赛总决赛二等奖', pic: p16 },
    { title: '2021年：长三角智慧城市建设项目突出贡献奖', pic: p17 },
    { title: '2021年：中国同城即时物流科技创新案例', pic: p18 },
    { title: '2020年：万达集团与华为联合举办的“5G创新应用大赛十强”', pic: p19 },
    { title: '2022年：智能汽车2022年度无人车风云榜十强企业', pic: p20 },
    { title: '2022年：智能汽车2022年度无人车风云榜推荐产品', pic: p21 },
    { title: '2022年：2022年低速无人驾驶场景落地案例TOP50', pic: p22 },
    { title: '2020年：获中国同城即时物流行业创新应用奖', pic: p23 },
    { title: '通过莱茵ISO 22737低速车场景评估测试（LSAD）', pic: p24 }
]

export const Honor = () => {
    const [imgWidth, setImgWidth] = useState(0)
    const [idx, setIdx] = useState(0)
    const [style, ulApi] = useSpring(() => ({ from: {x: 0} }))

    const [intervalTime, setIntervalTime] = useState(10000000000000)
    const [barAnmiTime, setBarAnmiTime] = useState(3000)
    const [isAuto, setIsAuto] = useState<boolean|'INIT'>('INIT')

    const { t } = useTranslation()

    const isEn = useIsEn()
    
    const prev = useMemoizedFn(() => {
        const newIdx = idx - 1
        if (newIdx < 0) {
            setIdx(list.length - 1)
        } else {
            setIdx(newIdx)
        }
    })

    const next = useMemoizedFn(() => {
        const newIdx = idx + 1
        if (newIdx > list.length - 1) {
            setIdx(0)
        } else {
            setIdx(newIdx)
        }

    })

    const manualPrev = useMemoizedFn(() => {
        setIsAuto(false)
        prev()
    })
    const manualNext = useMemoizedFn(() => {
        setIsAuto(false)
        next()
    })

    const swipeHandler = useSwipeable({
        onSwipedRight: () => {
            setIsAuto(false)
            prev()
        },
        onSwipedLeft: () => {
            setIsAuto(false)
            next()
        }
    })

    useDeepCompareEffect(() => {
        ulApi.start({ to: { x: -idx*imgWidth }, config: {duration: barAnmiTime}})
    }, [idx])

    useDeepCompareEffect(() => {
        if (isAuto === true) {
            setIntervalTime(3000)
            setBarAnmiTime(3000)
        } else if (isAuto === false){
            setIntervalTime(10000000000000)
            setBarAnmiTime(100)
            restoreInterval.run()
        }
    }, [isAuto])

    const restoreInterval = useDebounceFn(() => setIsAuto(true), { wait: 5000 })

    useInterval(next, intervalTime)

    return <div className={css.honor}>
        <h3>{t('企业荣誉')}</h3>
        <animated.ul className={css.pic} style={style} {...swipeHandler}>
            {list.map((item, idx) => <li ref={ref =>idx === 1 && setImgWidth(ref?.offsetWidth!)}>
                <img src={item.pic} alt=""/>
                {isEn || <div className={css.title}>{item.title}</div>}
            </li>)}
        </animated.ul>
        <ul className={css.dot}>
            {list.map((_, i) => <li className={classNames({[css.active]: idx === i})}></li>)}
        </ul>
        <div className={css['btn-grp']}>
            <img className={css.prev} src={iconPrev} alt="" onClick={manualPrev}/>
            <img className={css.next} src={iconNext} alt="" onClick={manualNext}/>
        </div>
    </div>
}