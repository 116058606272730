import { Block } from 'TechnologyPage/Block'
import css from './index.module.scss'
import pic1_1 from '@asset/0002-手机-核心技术-切图/深度学习/检测@2x.png'
import pic1_2 from '@asset/0002-手机-核心技术-切图/深度学习/全景分割@2x.png'
import pic1_3 from '@asset/0002-手机-核心技术-切图/深度学习/路面标志分割@2x.png'
import pic1_4 from '@asset/0002-手机-核心技术-切图/深度学习/预测轨迹生成@2x.png'
import pic3 from '@asset/0002-手机-核心技术-切图/深度学习/4D数据平台@2x.png'
import pic4 from '@asset/0002-手机-核心技术-切图/深度学习/深度学习在线推理库@2x.png'
import timeVideo from '@asset/0002-手机-核心技术-切图/深度学习/基于Transformer结构的时序学习.mp4'
import timePoster from './基于Transformer结构的时序学习-封面.png'
import picTitle from '@asset/0002-手机-核心技术-切图/深度学习/深度学习头部@2x.png'
import picTitleCar from '@asset/0002-手机-核心技术-切图/深度学习/深度学习头部-车@2x.png'
import { LazyImg } from 'widget/lazyImg'

const items = [{
    title: '大模型多任务训练', msg: '单一深度学习模型同时支持多种任务，包括且不限于：检测，全景分割，路面标志分割，速度估计，预测轨迹生成等。',
    children: [{
        title: '检测', pic: pic1_1
    }, {
        title: '全景分割', pic: pic1_2
    }, {
        title: '路面标志分割', pic: pic1_3
    }, {
        title: '预测轨迹生成', pic: pic1_4
    }]
}, {
    title: '基于Transformer结构的 时序学习', msg: '基于Transformer结构的模型，支持时序连续帧的输入，使得模型不仅能够完成单帧内的空间结构内容识别，也能实现速度、预测线等时序信息的估计，同时学习时空信息。',
    video: timeVideo,
    poster: timePoster
}, {
    title: '4D数据平台', msg: '传统数据平台主要针对单帧标注，而4D平台则是扩展到了连续帧标注，对于采集的时序上的连续帧数据能够利用大模型完成预标注甚至自动标注。',
    pic: pic3
}, {
    title: '深度学习在线推理库', msg: '由于模型的输入会出现多模态、4D时序等特点, 于是采用深度学习推理库的研发来保证模型推理延迟满足自动驾驶的实时性要求。',
    pic: pic4
}]

export const DeepLearn = () => <Block
    title="深度学习"
    desc="深度学习在白犀牛自动驾驶中的应用非常多样化，它可以为车辆提供更全面、更高效、更智能的服务。同时，随着深度学习技术的不断发展和完善，自动驾驶系统的性能和安全性也得到全面的提升。"
    items={items}
>
    <div className={css['deep-learn-bg']}>
        <LazyImg src={picTitle} alt="" className={css['deep-learn-pic']} />
        <LazyImg src={picTitleCar} alt="" className={css['deep-learn-pic-car']} />
    </div>
</Block>