import css from './index.module.scss'
import logo from '@asset/2023手机端官网-icon-svg/手机-LOGO.svg'
import menuBtn from '@asset/2023手机端官网-icon-svg/menu.svg'
import menuBtnExpand from '@asset/2023手机端官网-icon-svg/关闭导航.svg'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { animated, useSpring, useTransition } from '@react-spring/web'
import { useDeepCompareEffect, useMemoizedFn, useMount } from 'ahooks'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { goTop } from 'widget/goTop'

const _menuData = [
    { title: '首页', url: '/' },
    { title: '核心技术', url: '/tech' },
    { title: '服务中心', url: '/service' },
    { title: '新闻资讯', url: '/news' },
    { title: '关于我们', url: '/about-us' },
    { title: '联系我们', url: '/contact-us' },
    { element: <div><span className={css.chinese}>中文</span>&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;<span className={css.en}>EN</span></div> },
]

export const Banner = () => {

    const { t, i18n } = useTranslation()

    const [isExpand, setIsExpand] = useState(false)

    const [btnAnimate, api] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 }
    }))

    const [btnAnimateExpand, expandApi] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 }
    }))

    const menuData = useMemo(() => isExpand ? _menuData: [], [isExpand])

    const transitions = useTransition(menuData, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        trail: isExpand ? 100: -100,
        delay: 200,
        config: {
            duration: 200,
        }
    })

    useDeepCompareEffect(() => {
            api.start({opacity: isExpand ? 0: 1, config: {duration: 300}})
            expandApi.start({opacity: isExpand ? 1: 0, config: {duration: 300}})
    }, [isExpand])

    const useEnglish = useMemoizedFn(() => {
        i18n.changeLanguage('en-US')
    })

    const useChinese = useMemoizedFn(() => {
        i18n.changeLanguage('zh-CN')
    })

    const isEn = useMemo(() => i18n.resolvedLanguage === 'en', [i18n.resolvedLanguage])

    return <>
        <animated.div className={classNames({[css.banner]: true, [css.expand]: isExpand})}>
            <img className={css.logo} src={logo} alt=''/>
            <div onClick={() => setIsExpand(!isExpand)}>
                <animated.img style={btnAnimate} className={css['menu-btn']} src={menuBtn} alt=''/>
                <animated.img style={btnAnimateExpand} className={css['menu-btn']} src={menuBtnExpand} alt=''/>
            </div>
            <ul className={css.menu}>
                {transitions((style, item) => (<animated.li style={style} key={item.title}>{
                    item.url 
                        ? <NavLink to={item.url} onClick={() => {
                            setIsExpand(false)
                            goTop()
                        }}>{item.title ? t(item.title) : item.element}</NavLink>
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        : <a className={classNames({[css['is-en']]: isEn, [css['is-ch']]: !isEn})} onClick={() => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            if (isEn) useChinese()
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            else useEnglish()
                            setIsExpand(false)
                        }}>{item.title ? t(item.title) : item.element}</a>
                }</animated.li>))}
            </ul>
        </animated.div>
        <div className={css['banner-place-holder']}></div>
        
    </>
}