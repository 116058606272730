import css from './index.module.scss'
import points from '@asset/2023手机端官网-icon-svg/car-points.svg'
import car from '@asset/0001-手机-首页-切图/手机-关于我们@2x.png'
import { LazyImg } from 'widget/lazyImg'
import { NavLink } from 'react-router-dom'
import { goTop } from 'widget/goTop'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from '@react-spring/web'

export const AboutUs = () => {
    const { t } = useTranslation()
    const [picStyle, picApi] = useSpring(() => ({
        from: { opacity: 0 },
        to: [
            { opacity: 1 },
            { opacity: 0 },
            { opacity: 1 },
            { opacity: 0 },
            { opacity: 1 }
        ]
    }))
    const AnimatedLazyImg = animated(LazyImg)

    return <div className={css['about-us']}>
        <h3>{t('关于我们')}</h3>
        <div className={css.desc}>
        {t('白犀牛是一家L4自动驾驶公司，始终专注于城市公开道路上的自动驾驶产品和服务。以无人配送为起点，致力于让物流运输成本更低，让人类出行更安全、更经济。')}
        </div>
        <div className={css.pic}>
            <animated.div style={picStyle} >
                <LazyImg className={css.points} src={points} alt={''}/>
            </animated.div>
            
            <LazyImg className={css.car} src={car} alt={''} onLoad={() => setTimeout(() => picApi.resume(), 1000)}/>
        </div>
        <NavLink to={'/about-us'} onClick={goTop}><div className={css.btn}>{t('了解详情')}</div></NavLink>
    </div>
}