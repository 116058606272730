import { animated, useSpring } from "@react-spring/web";
import { useMemoizedFn } from "ahooks";
import { FC } from "react";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";

export const LazyImg:FC<LazyLoadImageProps & {delay?: number}> = (props) => {
    const [style, api] = useSpring(() => ({
        from: { opacity: 0 }
    }))
    const onLoad = useMemoizedFn(() => {
        api.start({to: {opacity: 1}, config: {duration: 1000}, delay: props.delay})
    })
    const AnimatedLazyLoadImage = animated(LazyLoadImage)
    return <AnimatedLazyLoadImage width={100} threshold={-100} placeholderSrc={''} {...props} onLoad={onLoad} style={style}></AnimatedLazyLoadImage>
}