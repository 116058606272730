import { LazyImg } from 'widget/lazyImg'
import css from './index.module.scss'
import pic from '@asset/0006-手机-联系我们/手机-加入我们@2x.png'
import { useTranslation } from 'react-i18next'
import { useIsEn } from 'widget/hook/useIsEn.hook'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import { useDeepCompareEffect, useMemoizedFn } from 'ahooks'
import { useRef } from 'react'

const jobs = [
    { title: '自动驾驶规划算法工程师', url: 'https://www.zhipin.com/job_detail/655676675ebaf8211nF-2du6EFFQ.html?lid=4kooenojeDD.search.15&securityId=ZDR4h2gkIqpoW-213ic5mX_7uRXOUfn-qJbBgURv4htutMq1w_N8vOnQc9o54CAeYwItSWdDPQ2668nXFJe7JKwQ_aFzhCXszXlM4YSDQIpp-HETUw96uS_mPH2fZx0KA0oYfe0-cfZBkTHEAkouSBc~&sessionId=' },
    { title: '自动驾驶定位地图算法工程师', url: 'https://www.zhipin.com/job_detail/b02dd649cb4b8f271nF_2d65FldV.html?lid=4kAGbzlBR4y.search.13&securityId=QVSASWqz4l8xp-w192_2PFznU6xnU9RmJcNfaY9G-tJ9P_5NkT2fzLFqracqfm0MPNlPYqOycwWODQCSOF1tLFCC1zYf1A-0GcY8G2XyoFI13kYL-Fpifcp4_1X8iHNRTWvnEXpWKYfAiUK8fi0jM_A~&sessionId=' },
    { title: '自动驾驶感知算法工程师', url: 'https://www.zhipin.com/job_detail/cc8e19b2c0ffb5e11nF-2du5E1JQ.html?lid=4kAGbzlBR4y.search.30&securityId=TLeKokM7S0Oux-11f_Hm7RGhfZpb-kSdIgZyR0ZWYUfEQdOUlc3tJbcEUaIWanbYctOuAeuWE-58C7Ziodsga4n4Qi5OZMicdNdJVaGfKMW0gZ5ICUQdADQ2IH5oofsUJlYLhuakxhy2psj9D7MXAmk~&sessionId=' },
    { title: '自动驾驶车载系统工程师', url: 'https://www.zhipin.com/job_detail/8d835e4774b5f2641nBy2N-8EFFS.html?lid=4kAGbzlBR4y.search.34&securityId=CtXdGY4Mg2BQL-S1mWEb3vA-c_5iKa_Mc47qAK15Ews9XCzLMnGK_CmAJheHrB8sn9tZYBUdAfGQIBYpyYnVlRKJBKEpL_4xU_BAIEuHM_-zV-ytuvKnetkaYGgpJBtonw6pxwB-DQT7ia5gJSetatk%7E&sessionId=' },
    { title: '自动驾驶电子工程师', url: 'https://www.zhipin.com/job_detail/19972d042c7b66961nx72tu0EFtX.html?lid=4k9Icg3o35L.search.32&securityId=ZfaykZjQO0p4L-C1T-dA71mraB8b7bklOV1ZRTK8BqdFPd_-tiU49kvM74LZ7K5CKz7RWh_BV3Oe9YQYHtcemE-4rpeA87Ms0NNJVByt2EvZogFdtLrfZey0WfKBZzNeVf6Tkg8kDfSMhH_BjQwYPWg~&sessionId=' }
]
export const Join = () => {
    const { t } = useTranslation()
    const isEn = useIsEn()
    const { pathname } = useLocation()
    const h3ref = useRef<HTMLHeadingElement>(null)

    useDeepCompareEffect(() => {
        const ele = h3ref.current
        if (pathname.indexOf('join-us') < 0) return
        if (!ele) return
        const top = ele.offsetTop
        setTimeout(() => {
            window.scrollTo({
              top: top - 30,
              behavior: 'smooth'
            })
        }, 100)
    }, [pathname])

    return <div className={classNames({[css.join]: true, [css['is-en']]: isEn})}>
        <h3 ref={h3ref}>{t('加入我们')}</h3>
        <LazyImg src={pic} alt="" />
        <h6>{t('招聘岗位')}</h6>
        <ul>
            {jobs.map(job => <li onClick={() => window.open(job.url)}>{t(job.title)}</li>)}
        </ul>
        <span>{t('简历投递')} zhaopin@rino.ai</span>
    </div>
}