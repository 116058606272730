import { Block } from "TechnologyPage/Block"
import pic1 from '@asset/0002-手机-核心技术-切图/自研硬件/数据处理板@2x.png'
import pic2 from '@asset/0002-手机-核心技术-切图/自研硬件/计算平台@2x.png'
import pic3 from '@asset/0002-手机-核心技术-切图/自研硬件/定位盒子@2x.png'
import pic4 from '@asset/0002-手机-核心技术-切图/自研硬件/传感器套件@2x.png'
import pic5 from '@asset/0002-手机-核心技术-切图/自研硬件/摄像头自清洁@2x.png'
import pic6 from '@asset/0002-手机-核心技术-切图/自研硬件/智能人车交互@2x.png'
import titlePic from '@asset/0002-手机-核心技术-切图/自研硬件/自研硬件头部装饰@2x.png'
import titlePicCar from '@asset/0002-手机-核心技术-切图/自研硬件/自研硬件小车@2x.png'

import css from './index.module.scss'
import { LazyImg } from "widget/lazyImg"


const items = [{
    title: '数据处理板', msg: 'LIDAR与CAMERA采集、同步、预处理板，提高计算平台实时性。',
    pic: pic1
}, {
    title: '计算平台', msg: '自研高性能、高可靠性、低功耗、低成本计算平台。',
    pic: pic2
}, {
    title: '定位盒子', msg: '自研融合了IMU、CANBUS、GNSS、LIDAR 和CAMERA信息的高精度融合定位设备。',
    pic: pic3
}, {
    title: '传感器套件', msg: '集成了电器设备、雷达、摄像头等多类传感器设备，易于快速部署。',
    pic: pic4
}, {
    title: '摄像头自清洁', msg: '研发了应对雨、雪等恶劣天气的气刀清洁系统，加强全天候运营能力。',
    pic: pic5
}, {
    title: '智能人车交互', msg: '根据场景语音或者灯光提示行人与车辆，并且可以实时语音对话。',
    pic: pic6
}]

export const Hardware = () => <Block
    title={'自研硬件'}
    desc={'硬件平台的设计直接决定了自动驾驶系统对环境的理解能力，白犀牛自主研发的数据处理与计算平台，结合性能卓越的传感器套件，保障了白犀牛自动驾驶的高性能，高可靠性和高适配性。'}
    items={items}
>
    <div className={css['hardware-bg']}>
        <LazyImg src={titlePic} alt="" className={css['title-pic']} />
        <LazyImg src={titlePicCar} alt="" className={css['title-pic-car']} />
    </div>
</Block>