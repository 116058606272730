import { AboutUs } from "AboutUs";
import { Banner } from "Banner";
import { Footer } from "Footer";
import { Index } from "IndexPage";
import { JoinAndContact } from "JoinAndContact";
import { Join } from "JoinAndContact/Join";
import { NewsPage } from "NewsPage";
import { ServicePage } from "ServicePage";
import { TechnologyPage } from "TechnologyPage";
import {
    createHashRouter,
    Outlet,
    RouterProvider,
  } from "react-router-dom";

const PageContainer = () => <>
  <Banner />
  <Outlet />
  <Footer />
</>


const router = createHashRouter([
  {
    path: '/',
    element: <PageContainer />,
    children: [
      {
        path: '/',
        element: <Index />
      }, {
        path: '/tech',
        element: <TechnologyPage />
      }, {
        path: '/service',
        element: <ServicePage />
      }, {
        path: '/news',
        element: <NewsPage />
      }, {
        path: '/about-us',
        element: <AboutUs />
      }, {
        path: '/contact-us',
        element: <JoinAndContact />
      }, {
        path: '/join-us',
        element: <JoinAndContact />
      }
    ]
  },
])

export const App = () => <RouterProvider router={router} />