import css from './index.module.scss'
import p201904 from '@asset/0005-手机-关于我们/发展历程/2019-04@2x.png'
import p201909 from '@asset/0005-手机-关于我们/发展历程/2019-09@2x.png'
import p202002 from '@asset/0005-手机-关于我们/发展历程/2020-02@2x.png'
import p202003 from '@asset/0005-手机-关于我们/发展历程/2020-03@2x.png'
import p202005 from '@asset/0005-手机-关于我们/发展历程/2020-05@2x.png'
import p202008 from '@asset/0005-手机-关于我们/发展历程/2020-08@2x.png'
import p202009 from '@asset/0005-手机-关于我们/发展历程/2020-09@2x.png'
import p202010 from '@asset/0005-手机-关于我们/发展历程/2020-10@2x.png'
import p202105 from '@asset/0005-手机-关于我们/发展历程/2021-05@2x.png'
import p202107 from '@asset/0005-手机-关于我们/发展历程/2021-07@2x.png'
import p202112 from '@asset/0005-手机-关于我们/发展历程/2021-12-1@2x.png'
import p2021122 from '@asset/0005-手机-关于我们/发展历程/2021-12-2@2x.png'
import p202201 from '@asset/0005-手机-关于我们/发展历程/2022-01@2x.png'
import p202203 from '@asset/0005-手机-关于我们/发展历程/2022-03-1@2x.png'
import p2022032 from '@asset/0005-手机-关于我们/发展历程/2022-03-2@2x.png'
import p202204 from '@asset/0005-手机-关于我们/发展历程/2022-04@2x.png'
import p202209 from '@asset/0005-手机-关于我们/发展历程/2022-09-1@2x.png'
import p2022092 from '@asset/0005-手机-关于我们/发展历程/2022-09-2@2x.png'
import p202210 from '@asset/0005-手机-关于我们/发展历程/2022-10@2x.png'
import p202211 from '@asset/0005-手机-关于我们/发展历程/2022-11@2x.png'
import p202303 from '@asset/0005-手机-关于我们/发展历程/2023-03@2x.png'
import p202304 from '@asset/0005-手机-关于我们/发展历程/2023-04@2x.png'
import p202306 from '@asset/0005-手机-关于我们/发展历程/2023-06@2x.png'
import p202308 from '@asset/0005-手机-关于我们/发展历程/2023-09@2x.png'
import { animated, useSpring } from '@react-spring/web'
import { useDebounceFn, useDeepCompareEffect, useInterval, useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useSwipeable } from 'react-swipeable'
import { useIsEn } from 'widget/hook/useIsEn.hook'
import iconPrev from '@asset/2023手机端官网-icon-svg/left-btn-blue.svg'
import iconNext from '@asset/2023手机端官网-icon-svg/right-btn-blue.svg'

const events = [{
    date: '2019/04', msg: '正式成立，辰韬资本种子轮投资',
    pic: p201904
}, {
    date: '2019/09', msg: '首款车下线，正式启动末端配送业务',
    pic: p201909
}, {
    date: '2020/02', msg: '驰援武汉抗击疫情，全球首家进入方舱医院协助进行物资配送的自动驾驶企业',
    pic: p202002
}, {
    date: '2020/03', msg: '辰韬资本追加投资',
    pic: p202003
}, {
    date: '2020/05', msg: '推出白犀严选，首创线上便利店模式，小程序下单无人车配送等全流程把控',
    pic: p202005
}, {
    date: '2020/08', msg: '与医药新零售头部企业叮当快药达成合作，中国首家将自动驾驶技术运用于药品运输企业',
    pic: p202008
}, {
    date: '2020/09', msg: '率先落地上海，运用自动驾驶技术开展物品配送，进行城市道路示范运营',
    pic: p202009
}, {
    date: '2020/10', msg: '与国内头部商超永辉超市达成战略合作，中国首家将自动驾驶技术运用于商超/生鲜无人配送企业',
    pic: p202010
}, {
    date: '2021/05', msg: '落地成都，运用自动驾驶技术为零售商超提供配送服务',
    pic: p202105
}, {
    date: '2021/07', msg: '线性资本领投，获近千万美元 Pre A 轮融资',
    pic: p202107
}, {
    date: '2021/12', msg: '索道资本、长安私人资本、基石资本、线性资本5000万元 Pre A+轮融资',
    pic: p202112
}, {
    date: '2021/12', msg: '获国家高新技术企业认证',
    pic: p2021122
}, {
    date: '2022/01', msg: '与天虹达成战略合作，深圳首家运用自动驾驶技术开展商超无人配送服务企业',
    pic: p202201
}, {
    date: '2022/03', msg: '大中华区首个通过L4级自动驾驶系统国际标准ISO 22737自动驾驶车辆场景评估测试企业',
    pic: p202203
}, {
    date: '2022/03', msg: '落地合肥，首家在合肥正式启动公开道路配送的自动驾驶企业',
    pic: p2022032
}, {
    date: '2022/04', msg: '上海抗疫保供，累计配送近2万件物资，服务约4万居民',
    pic: p202204
}, {
    date: '2022/09', msg: '获交通运输部首批智能交通先导应用试点',
    pic: p202209
}, {
    date: '2022/09', msg: '获合肥市首批低速无人车商业运营许可',
    pic: p2022092
}, {
    date: '2022/10', msg: '获北京市“专精特新”中小企业认证',
    pic: p202210
}, {
    date: '2022/11', msg: '与零售商超合作伙伴共创“虚拟前置仓”新业态',
    pic: p202211
}, {
    date: '2023/03', msg: '上海市首批智能网联汽车示范应用创新项目（揭榜挂帅）',
    pic: p202303
}, {
    date: '2023/04', msg: '完成A轮融资，辰韬资本领投，线性资本跟投',
    pic: p202304
}, {
    date: '2023/06', msg: '获深圳首批无人小车路权牌照',
    pic: p202306
}, {
    date: '2023/09', msg: '新车型Robovan下线，正式启动同城货运业务',
    pic: p202308
}]

const yearMap: {[key: string]: number} = {}

let yearCount = 0
let monthCount = 0
const getYear = (date: string) => date.split('/')[0]
const precentMap = [{yearCount: 0, monthCount: 0}]
for (let i=1; i< events.length; i++) {
    const prevYear = getYear(events[i-1].date)
    const year = getYear(events[i].date)
    if (prevYear === year) {
        monthCount += 1
    } else {
        yearMap[yearCount] = monthCount + 1
        yearCount += 1
        monthCount = 0
    }
    precentMap.push({
        yearCount, monthCount
    })
}
yearMap[yearCount] = monthCount + 1
const totalYearCount = yearCount + 1

console.log(precentMap, yearMap)

export const History = () => {
    const [idx, setIdx] = useState(0)
    const [ barItemWidth, setBarItemWidth ] = useState(0)
    const [imgStyle, imgAnmiApi] = useSpring(() => ({from: { opacity: 0 }}))
    const [percentStyle, barAnmiApi] = useSpring(() => ({from: { clipPath: `inset(0 0 0 0 round 0)` },}))
    const [intervalTime, setIntervalTime] = useState(10000000000000)
    const [barAnmiTime, setBarAnmiTime] = useState(3000)
    const [isAuto, setIsAuto] = useState<boolean|'INIT'>('INIT')
    const { t } = useTranslation()
    const isEn = useIsEn()

    useDeepCompareEffect(() => {
        if (idx < 0) {
            setIdx(events.length - 1)
        } else if (idx >= events.length) {
            setIdx(0)
        }
    }, [idx])

    useDeepCompareEffect(() => {
        imgAnmiApi.set({opacity: 0})
        if (!precentMap[idx]) {
            let px = totalYearCount * barItemWidth
            barAnmiApi.set({clipPath: `inset(0 ${px}px 0 0 round 0)` })
            return
        }
        const {yearCount, monthCount} = precentMap[idx]
        let px = (totalYearCount - (yearCount + (monthCount + 1) / yearMap[yearCount])) * barItemWidth
        barAnmiApi.start({to: {clipPath: `inset(0 ${px}px 0 0 round 0)` }, config: {duration: barAnmiTime}})
    }, [idx, barItemWidth])

    const event = useMemo(() => events?.[idx], [idx])

    const prev = useMemoizedFn(() => setIdx(idx - 1))
    const next = useMemoizedFn(() => {
        setIdx(idx + 1)
    })

    const manualPrev = useMemoizedFn(() => {
        setIsAuto(false)
        prev()
    })

    const manualNext = useMemoizedFn(() => {
        setIsAuto(false)
        next()
    })

    const swipeHandler = useSwipeable({
        onSwipedRight: manualPrev,
        onSwipedLeft: manualNext
    })

    useInterval(next, intervalTime)

    useDeepCompareEffect(() => {
        if (isAuto === true) {
            setIntervalTime(3000)
            setBarAnmiTime(3000)
        } else if (isAuto === false){
            setIntervalTime(10000000000000)
            setBarAnmiTime(0)
            restoreInterval.run()
        }
    }, [isAuto])

    const restoreInterval = useDebounceFn(() => setIsAuto(true), { wait: 5000 })

    return <div className={classNames({[css.history]: true, [css['is-en']]: isEn})}>
        <h3>{t('发展历程')}</h3>
        <div className={css.date}>{event?.date}</div>
        <div className={css.detail}>{t(event?.msg)}</div>
        <LazyLoadComponent afterLoad={() => {
            setIsAuto(true)
        }}>
            <div {...swipeHandler}>
                <animated.img style={imgStyle} src={event?.pic} className={css.pic} alt="" onLoad={() => imgAnmiApi.start({to: {opacity: 1}, config: {duration: 800}})} />
            </div>
        </LazyLoadComponent>
        <ul className={css['time-bar']}>
            <ul className={css['time-bar-before']}>
                <li ref={ref => setBarItemWidth(ref?.offsetWidth!)}></li><li></li><li></li><li></li><li></li>
            </ul>
            <animated.ul className={css['time-bar-active']} style={percentStyle}>
                <li></li><li></li><li></li><li></li><li></li>
            </animated.ul>
            <li>2019</li>
            <li>2020</li>
            <li>2021</li>
            <li>2022</li>
            <li>2023</li>
        </ul>
        <div className={css['btn-grp']}>
            <img src={iconPrev} className={css.prev} alt={''} onClick={manualPrev}></img>
            <img src={iconNext} className={css.next} alt={''} onClick={manualNext}></img>
        </div>
    </div>
}