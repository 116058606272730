import { DeepLearn } from "./DeepLearn";
import { Hardware } from "./Hardware";
import { Perception } from "./Perception";
import { Pic } from "./Pic";
import { Planning } from "./Planning";
import { SchedulePlatform } from "./SchedulePlatform";
import { Simulation } from "./Simulation";

export const TechnologyPage = () => <div>
    <Pic />
    <Perception />
    <DeepLearn />
    <Hardware />
    <Planning />
    <Simulation />
    <SchedulePlatform />
</div>