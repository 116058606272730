import { Block } from "TechnologyPage/Block";

import pic1 from '@asset/0002-手机-核心技术-切图/仿真平台/城市物流交通场景仿真@2x.png'
import pic2 from '@asset/0002-手机-核心技术-切图/仿真平台/Logsim-ADS自动转换@2x.png'
import pic3 from '@asset/0002-手机-核心技术-切图/仿真平台/Worldsim-ADS可视化编辑@2x.png'
import pic4 from '@asset/0002-手机-核心技术-切图/仿真平台/传感器仿真@2x.png'

import titlePic from '@asset/0002-手机-核心技术-切图/仿真平台/仿真平台-头部光影@2x.png'
import titlePicCar from '@asset/0002-手机-核心技术-切图/仿真平台/仿真平台头部小车@2x.png'

import css from './index.module.scss'
import { LazyImg } from "widget/lazyImg";

const items = [{
    title: '城市物流交通场景仿真', msg: '采用真实门店及配送点数据，采用模拟交通参与者，对自动驾驶系统进行综合测试。可实现单车智能、多车智能等多种形式的开发测试或研究。',
    pic: pic1
}, {
    title: 'Logsim ADS自动转换', msg: '对于实际路测中的高价值自动驾驶场景数据，在经测试人员挑选或数据挖掘筛选后，可以自动转化对接成Logsim类型的ADS（自动驾驶仿真场景），以方便研发后续进行回归测试。',
    pic: pic2
},{
    title: 'Worldsim ADS可视化编辑', msg: '对于现实中成本较高、风险较大等不容易获取测试数据的场景，可通过在仿真系统的Worldsim中自由编辑来设计ADS。此外，通过Fuzzing技术可以高效批量生成场景数据，可进一步用来辅助生成ADS，或用于自动驾驶参数训练。',
    pic: pic3
}, {
    title: '传感器仿真', msg: '传感器仿真主要对摄像头、激光雷达、毫米波雷达、定位（GPS、IMU）等感知传感器进行模拟测试。可从物理信号仿真、原始信号仿真和目标级信号仿真三个不同层级，来实现不同类型的仿真测试。',
    pic: pic4
}]

export const Simulation = () => <Block
    title={'仿真平台'}
    desc={'仿真是实现规模化应用自动驾驶技术的关键路径。白犀牛自动驾驶借助仿真及相关工具链，能形成高效的数据测试闭环，支持算法的测试和高效迭代。'}
    items={items}
>
    <div className={css['simulation-bg']}>
        <LazyImg src={titlePic} alt="" className={css['title-pic']}/>
        <LazyImg src={titlePicCar} alt="" className={css['title-pic-car']} />
    </div>
</Block>