import css from './index.module.scss'

import pic1 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0001-达达快送@2x.png'
import pic2 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0002-大润发@2x.png'
import pic3 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0003-叮当快药@2x.png'
import pic4 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0004-盒马@2x.png'
import pic5 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0005-货拉拉@2x.png'
import pic6 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0006-京东到家@2x.png'
import pic7 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0007-快狗打车@2x.png'
import pic8 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0008-顺丰速运@2x.png'
import pic9 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0009-顺丰同城@2x.png'
import pic10 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0010-生鲜传奇@2x.png'
import pic11 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0011-天虹@2x.png'
import pic12 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0012-永辉超市@2x.png'
import pic13 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0013-中通快递@2x.png'
import pic14 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0014-辰韬资本@2x.png'
import pic15 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0015-长安@2x.png'
import pic16 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0016-基石资本@2x.png'
import pic17 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0017-索道投资@2x.png'
import pic18 from '@asset/0001-手机-首页-切图/手机-首页-合作伙伴/0018-线性资本@2x.png'
import { useTranslation } from 'react-i18next'
import { LazyImg } from 'widget/lazyImg'

const pics = [
    pic1, pic2, pic3,
    pic4, pic5, pic6,
    pic7, pic8, pic9,
    pic10, pic11, pic12,
    pic13, pic14, pic15,
    pic16, pic17, pic18
]
export const Partner = () => {
    const { t } = useTranslation()
    return <div className={css.partner}>
        <h3>{t('合作伙伴')}</h3>
        <ul className={css.list}>
            {pics.map((pic, idx) => <LazyImg src={pic} alt=""/>)}
        </ul>
    </div>
}