import css from './index.module.scss'
import logo from '@asset/2023手机端官网-icon-svg/手机-LOGO-white.svg'
import wechat from '@asset/2023手机端官网-icon-svg/wechat.svg'
import wechatBlack from '@asset/2023手机端官网-icon-svg/wechat-black.svg'
import qrcode from '@asset/0001-手机-首页-切图/微信二维码@2x.png'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { goTop } from 'widget/goTop'
import { useTranslation } from 'react-i18next'
import { useIsEn } from 'widget/hook/useIsEn.hook'
import classNames from 'classnames'

export const Footer = () => {
    const [showQrCode, setShowQrCode] = useState(false)
    const { t } = useTranslation()
    const isEn = useIsEn()

    return <div className={classNames({[css.footer]: true, [css['is-en']]: isEn})}>
        <img src={logo} alt="" className={css.logo}/>
        <div className={css['wechat-wrap']} onClick={() => setShowQrCode(!showQrCode)}>
            <img src={showQrCode ? wechatBlack: wechat} alt="" className={css.wechat} />
            {showQrCode && <div className={css.qrcode}>
                <img src={qrcode} alt="" className={css.code} />
                <div className={css.word}>{t('扫码关注微信公众号获取最新资讯')}</div>
            </div>}
        </div>
        <ul className={css['menu-left']}>
            <li><NavLink to={'/tech'} onClick={goTop}>{t('核心技术')}</NavLink></li>
            <li><NavLink to={'/service'} onClick={goTop}>{t('服务中心')}</NavLink></li>
            <li><NavLink to={'/news'} onClick={goTop}>{t('新闻资讯')}</NavLink></li>
        </ul>
        <ul className={css['menu-right']}>
            <li><NavLink to={'/about-us'} onClick={goTop}>{t('关于我们')}</NavLink></li>
            <li><NavLink to={'/contact-us'} onClick={goTop}>{t('联系我们')}</NavLink></li>
            <li><NavLink to={'/join-us'} onClick={goTop}>{t('加入我们')}</NavLink></li>
        </ul>

        <ul className={css['contact-infomation']}>
            <li><span>{t('联系电话')}</span><span>400-113-0423</span></li>
            <li><span>{t('合作邮箱')}</span><span>contacts@rino.ai</span></li>
            <li><span>{t('总部地址')}</span><span>{t('北京市 海淀区 花园东路11号 泰兴大厦 9层')}</span></li>
        </ul>

        <a className={css.ipc} href="https://beian.miit.gov.cn/">
            ©2021白犀牛智达(北京)科技有限公司  京ICP备19027919号-1
        </a>
    </div>
}