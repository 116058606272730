import { Explode } from 'widget/explode'
import css from './index.module.scss'
import { NavLink } from 'react-router-dom'
import { goTop } from 'widget/goTop'
import { useTranslation } from 'react-i18next'

export const Technology = () => {
    const { t } = useTranslation()
    return <div className={css.technology}>
        <h3>{t('核心技术')}</h3>
        <div className={css.desc}>
        {t('我们拥有先进且多样化的自动驾驶能力，包括更加精确、可靠、安全的感知信息，更加优越的车辆性能以及更高精度的车辆控制。致力于从物流到出行实现更高效、安全、经济的自动驾驶。')} 
        </div>
        <div className={css.pic}>
            <Explode />
        </div>
        <NavLink to={'/tech'} onClick={goTop}><div className={css.btn}>{t('了解详情')}</div></NavLink>
    </div>
}