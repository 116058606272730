import css from './index.module.scss'
import bannerBg from '@asset/0004-手机-新闻资讯/新闻资讯背景@1x.png'
import news01 from '@asset/0004-手机-新闻资讯/0001新闻@2x.png'
import news02 from '@asset/0004-手机-新闻资讯/0002新闻@2x.png'
import news03 from '@asset/0004-手机-新闻资讯/0003-新闻@2x.png'
import news04 from '@asset/0004-手机-新闻资讯/0004新闻@2x.png'
import news05 from '@asset/0004-手机-新闻资讯/0005新闻@2x.png'
import news06 from '@asset/0004-手机-新闻资讯/0006新闻@2x.png'
import news07 from '@asset/0004-手机-新闻资讯/0007新闻@2x.png'
import news08 from '@asset/0004-手机-新闻资讯/0008新闻@2x.png'
import news09 from '@asset/0004-手机-新闻资讯/0009新闻@2x.png'
import news10 from '@asset/0004-手机-新闻资讯/0010新闻@2x.png'
import news11 from '@asset/0004-手机-新闻资讯/0011新闻@2x.png'
import news12 from '@asset/0004-手机-新闻资讯/0012新闻@2x.png'
import news13 from '@asset/0004-手机-新闻资讯/0013新闻@2x.png'
import news14 from '@asset/0004-手机-新闻资讯/0014新闻@2x.png'
import news15 from '@asset/0004-手机-新闻资讯/0015新闻@2x.png'
import news16 from '@asset/0004-手机-新闻资讯/0016新闻.png'
import news17 from '@asset/0004-手机-新闻资讯/20230905-重庆百货签约-PC-2倍.png'
import news18 from '@asset/0004-手机-新闻资讯/20230926-顺义合作-pc-2倍.png'
import news19 from '@asset/0004-手机-新闻资讯/20230926-世界制造大会-pc-2倍.png'
import { LazyImg } from 'widget/lazyImg'
import { useTranslation } from 'react-i18next'


const news = [
    {img: news01, title: 'TUV南德与白犀牛达成全球战略合作，携手开拓国际市场', time: [2023, 2, 17],
     url: 'https://www.163.com/dy/article/HTPFE2SS051494RM.html'
    },
    {img: news02, title: '低速无人车产业将迎来“国家裁判”', time: [2022, 11, 12],
     url: 'https://baijiahao.baidu.com/s?id=1749276166380892603&wfr=spider&for=pc'
    },
    {img: news03, title: '最前线｜合肥首辆无人配送车白犀牛获商业运营牌照，低速无人车商业价值获官方认可', time: [2022, 9, 19],
     url: 'https://baijiahao.baidu.com/s?id=1744386761241883740&wfr=spider&for=pc'
    },
    {img: news04, title: '科创中心“核”动力｜白犀牛智达：新时代的配送员', time: [2022, 9, 5],
     url: 'https://baijiahao.baidu.com/s?id=1743128699646777580&wfr=spider&for=pc'
    },
    {img: news05, title: '白犀牛夏添：未来让无人配送车成为城市基础设施', time: [2022, 8, 17],
     url: 'https://gongyi.sohu.com/a/557976543_121388846'
    },
    {img: news06, title: '无人车都来了！永辉携白犀牛支持安亭保供，已完成2万件配送！', time: [2022, 4, 13],
     url: 'https://baijiahao.baidu.com/s?id=1729985020022667334&wfr=spider&for=pc'
    },
    {img: news07, title: '白犀牛通过首个L4级自动驾驶系统国际标准场景评估测试', time: [2022, 3, 18],
     url: 'http://www.jjckb.cn/2022-03/18/c_1310519448.htm'
    },
    {img: news08, title: '白犀牛无人配送车试跑合肥高新区', time: [2022, 3, 15],
     url: 'http://www.stdaily.com/index/kejixinwen/202203/54c02ab6566a41459ca68fce9accfad5.shtml'
    },
    {img: news09, title: '无人配送车白犀牛试跑合肥街头', time: [2022, 3, 13],
     url: 'https://baijiahao.baidu.com/s?id=1727158924170869789&wfr=spider&for=pc'
    },
    {img: news10, title: '白犀牛CEO朱磊：末端无人配送还没到抢市场的阶段｜探路2022', time: [2022, 1, 28],
     url: 'https://baijiahao.baidu.com/s?id=1723165708193145444&wfr=spider&for=pc'
    },
    {img: news11, title: '天虹超市 X 白犀牛 商超无人配送服务首次落地深圳', time: [2022, 1, 19],
     url: 'http://qiye.chinadaily.com.cn/a/202201/19/WS61e7b90ea3107be497a02f13.html'
    },
    {img: news12, title: '36氪首发｜无人配送公司白犀牛获5000万Pre A+轮融资，5年内无人车日活量达5000台', time: [2021, 12, 30],
     url: 'https://baijiahao.baidu.com/s?id=1720527109403686915&wfr=spider&for=pc'
    },
    {img: news13, title: '无人驾驶公司白犀牛获近千万美元融资', time: [2021, 7, 15],
     url: 'https://www.autohome.com.cn/news/202107/1177672.html'
    },
    {img: news14, title: '白犀牛联合永辉超市，推出零售无人配送', time: [2021, 10, 9],
     url: 'https://finance.sina.com.cn/tech/2020-10-09/doc-iivhuipp8626849.shtml'
    },
    {img: news15, title: '白犀牛无人车获辰韬资本追加投资', time: [2022, 3, 4],
     url: 'https://news.pedaily.cn/202003/452234.shtml'
    },
    {img: news16, title: '前百度自动驾驶核心成员加入「白犀牛」，研发末端即时配送无人车', time: [2020, 2, 19],
     url: 'https://36kr.com/p/1725100163073'
    },
    {img: news17, title: '白犀牛与重庆百货达成战略合作，共创零售配送新模式', time: [2023, 9, 5],
        url: 'http://www.cqsahngbao.cn/caijing/6733.html'
    },
    {img: news18, title: '顺义区政府携手白犀牛自动驾驶共建“智能网联汽车创新生态示范区”', time: [2023, 9, 26],
        url: 'https://wap.bjd.com.cn/news/2023/09/26/10576339.shtml'
    },
    {img: news19, title: '白犀牛自动驾驶新车型Robovan首次亮相，参展2023世界制造大会', time: [2023, 9, 26],
        url: 'https://www.cet.com.cn/wzsy/kjzx/3451691.shtml'
    }
]


export const NewsPage = () => {
    const { t } = useTranslation()
    return <>
        <div className={css['news-banner']}>
            <LazyImg src={bannerBg} alt="" placeholder={<img alt="" />}/>
            <h3>{t('新闻资讯')}</h3>
        </div>
        <ul className={css['news-grp']}>
            {news.map((item, idx) => <li onClick={() => window.open(item.url)}>
                <LazyImg src={item.img} alt="" placeholder={<img alt=""/>} delay={idx % 2 ? 300: 0}/>
                <h6>{t(item.title)}</h6>
                <div className={css.date}>{item.time[0]}{t('年')}{item.time[1]}{t('月')}{item.time[2]}{t('日')}</div>
            </li>)}
        </ul>
    </>
}