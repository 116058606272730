import css from './index.module.scss'
import bg from '@asset/0001-手机-首页-切图/手机-首页-服务/手机-首页-服务中心背景图@1x.png'
import menuItemIcon from '@asset/2023手机端官网-icon-svg/expand.svg'

import pic1 from '@asset/0001-手机-首页-切图/手机-首页-服务/手机-服务-商超即时配送@2x.png'
import pic2 from '@asset/0001-手机-首页-切图/手机-首页-服务/手机-服务-门店库存共享@2x.png'
import pic3 from '@asset/0001-手机-首页-切图/手机-首页-服务/手机-服务-快递网点接驳@2x.png'
import pic4 from '@asset/0001-手机-首页-切图/手机-首页-服务/手机-服务-一对一急送@2x.png'
import pic5 from '@asset/0001-手机-首页-切图/手机-首页-服务/手机-服务-冷链运输@2x.png'
import { useState } from 'react'
import { animated, useSprings } from '@react-spring/web'
import { useMemoizedFn, useMount } from 'ahooks'
import classNames from 'classnames'
import { LazyImg } from 'widget/lazyImg'
import { useTranslation } from 'react-i18next'


const menuItems: {
    title: string;
    desc: string;
    pic?: string;
}[] = [
    { title: '商超即时配送', desc: '我们帮助商超客户完成外卖订单的配送。通过白犀牛智能调度平台，动态调度无人车、自动规划车辆行驶路线和接驳地点，并调度接驳人员实现货品送到客户家中。', pic: pic1 },
    { title: '门店库存共享', desc: '我们使用无人车帮助零售客户实现多门店之间库存的动态调拨，从而使客户能够实现库存和实时销量的最佳匹配，提高潜在销售额的同时，降低潜在货损。', pic: pic2 },
    { title: '快递网点接驳', desc: '我们为快递网点或转运中心提供不间断高频次接驳服务，从而可以实现快递网点到驿站的取送、网点之间错分件、急件的转运，从而让快递运输效率更高、成本更低。', pic: pic3 },
    { title: '一对一急送', desc: '我们能够提供点对点即时送达服务，全程监控货品且无人参与，让时效更快、私密性更高。', pic: pic4 },
    { title: '冷链运输', desc: '我们的车厢拥有自动控温功能，针对生鲜类即时商品可全程保证冷链运输。且支持模块化、个性化定制，同时可保持24小时不间断运营，满足全时段消费需求。', pic: pic5 }
]

export const Service = () => {
    const [current, setCurrent] = useState(-1)
    
    const { t } = useTranslation()

    const [springs, api] = useSprings(menuItems.length, (i) => ({
        from: { maxHeight: 0 },
        to: { maxHeight: 0 },
    }))

    const toggle = useMemoizedFn((idx) => {
        api.start(i => {
            if (i === current)
            return { to: {maxHeight: 0}, config: { duration: 200 } }
        })
        if (current !== idx) {
            api.start(i => {
                if (i === idx) {
                    return { to: {maxHeight: 400}, config: { duration: 200 } }
                }
            })
            setCurrent(idx)
        } else {
            setCurrent(-1)
        }
        
    })

    useMount(() => toggle(0))

    return <div className={css.service}>
        <LazyImg src={bg} alt="" className={css.bg}/>
        <h3>{t('服务中心')}</h3>
        <div className={css.desc}>
        {t('我们提供城市物流领域先进的自动驾驶产品和综合解决方案，可满足货运、配送和其它城市服务中多样化且无处不在的自动驾驶需求。')}
        </div>
        <div className={css.menu}>
        {
            springs.map((style, idx) => {
                const item = menuItems[idx]
                return <div className={css.item} onClick={() => toggle(idx)}>
                <div className={classNames({[css['item-line']]: true, [css['expand']]: idx === current})}>
                    <img className={css['item-icon']} src={menuItemIcon} alt={''}/>
                    <div className={css['item-title']}>{t(item.title)}</div>
                </div>
                {
                    <animated.div style={style} className={css['item-content']}>
                        <div className={css['item-desc']}>{t(item.desc)}</div>
                        <LazyImg className={css['item-pic']} alt="" src={item.pic}/>
                    </animated.div>
                }
            </div>
            })
        }
        </div>
    </div>
}