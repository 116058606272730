
import { AboutUs } from "./AboutUs";
import { JoinUs } from "./JoinUs";
import { Partner } from "./Partner";
import { Service } from "./Service";
import { Technology } from "./Technology";
import { Video } from "./Video";

export const Index = () => <>
    <Video />
    <AboutUs />
    <Technology />
    <Service />
    <Partner />
    <JoinUs />
</>