import css from './index.module.scss'
import prevBtn from '@asset/2023手机端官网-icon-svg/left-btn.svg'
import nextBtn from '@asset/2023手机端官网-icon-svg/right-btn.svg'
import { animated, useSpring } from '@react-spring/web'
import { useDeepCompareEffect, useInterval, useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { FC, PropsWithChildren, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useSwipeable } from 'react-swipeable'
import { LoadingPic } from '../../widget/loader'
import { useIsEn } from '../../widget/hook/useIsEn.hook'
import imgPlayBtn from '@asset/2023手机端官网-icon-svg/播放按钮.svg'

type Item = {
    title: string,
    msg: string,
    pic?: string,
    video?: string,
    poster?: string,
    children?: {
        title: string,
        pic: string
    }[]
}

const PicItem: FC<{item: Item, setWidthHandler?: (width: number) => void, setLock?: () => void, setUnLock?: () => void}> = ({item, setWidthHandler, setLock, setUnLock}) => {
    const [detailIdx, setDetailIdx] = useState<number|null>()
    const { t } = useTranslation()

    const setIdx = useMemoizedFn(idx => {
        if (detailIdx != null) {
            setDetailIdx(null)
        } else {
            setDetailIdx(idx)
        }
    })

    const setWidth = useMemoizedFn(width => setWidthHandler && setWidthHandler(width))

    const [videoLoadedStyle, videoApi] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: true,
        config: {duration: 300}
    }))

    const [isLoading, setIsLoading] = useState(true)
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isManualPlay, setIsManualPlay] = useState(false)

    return <>
        {item.video && <div className={css['video-wrap']}>
            {isLoading && <LoadingPic />}
            <animated.div className={classNames({[css.video]: true, [css['is-manual-play']]: isManualPlay})} style={videoLoadedStyle} ref={ref => setWidth(ref?.offsetWidth)}>
                <video poster={item.poster} src={item.video} ref={videoRef} webkit-playsinline={true} playsInline={true} x5-video-player-type="h5-page" muted autoPlay loop onCanPlay={() => {
                    setIsLoading(false)
                    videoApi.resume()
                    videoRef.current?.play().catch(() => {
                        setIsManualPlay(true)
                    })
                }}/>
                <div className={css['play-btn-mask']}>
                    <img src={imgPlayBtn} alt="" className={css['play-btn']} onClick={() => {
                        videoRef.current?.play().then(() => {
                            setIsManualPlay(false)
                        })
                    }}/>
                </div>
            </animated.div>
        </div>}
        {item.pic && <img  src={item.pic} alt="" ref={ref => setWidth(ref?.width)}></img>}
        {item.children && <div className={css['pic-group']}>{
            item.children.map((item, idx) => <div className={css.pic} onClick={() => {
                setLock && setLock()
                setIdx(idx)
            }}>
                <img src={item.pic} alt="" />
                <div className={css.title}>{t(item.title)}</div>
            </div>)
        }{
            detailIdx != null && <div className={css['pic-detail']}  ref={ref => setWidth(ref?.offsetWidth)} onClick={() => {
                setUnLock && setUnLock()
                setIdx(null)
            }}>
                <div className={css.title}>{t(item.children[detailIdx].title)}</div>
                <img src={item.children[detailIdx].pic} alt="" />
            </div>
        }</div>}
    </>
}

const MAX_TIME = 500000000000

export const Block: FC<PropsWithChildren<{
    title: string,
    desc: string,
    items: Item[],
}>> = ({
    title, desc, items, children
}) => {
    const { t } = useTranslation()

    const [idx, setIdx] = useState(0)
    const [itemWidth, setItemWidth] = useState(0)


    const setLock = useMemoizedFn(() => setIntervalTime(500000000000))
    const setUnLock = useMemoizedFn(() => setIntervalTime(5000))


    const prev = useMemoizedFn(() => {
        setLock()
        setIdx(idx - 1)
        setTimeout(setUnLock, 5000)
    })

    const next = useMemoizedFn(() => {
        setLock()
        setIdx(idx + 1)
        setTimeout(setUnLock, 5000)
    })

    const swipeHandler = useSwipeable({
        onSwipedRight: prev,
        onSwipedLeft: next
    })

    const [style, api] = useSpring(() => ({
        from: {x: -itemWidth},
        to: {x: -itemWidth}
    }))

    useDeepCompareEffect(() => {
        (async () => {
            await api.start({x: -((idx+1) * itemWidth), config: { duration: 300 }})[0];
            let newIdx = idx
            if (idx < 0) {
                newIdx = items.length - 1
            }
            if (idx > (items.length - 1)) {
                newIdx = 0
            }
            api.set({x: -((newIdx + 1) * itemWidth)})
            setIdx(newIdx)
        })()
    }, [idx, itemWidth])

    const realIdx = useMemo(() => {
        if (idx < 0) return items.length - 1
        if (idx > (items.length - 1)) return 0
        return idx
    }, [idx, items.length])

    const [intervalTime, setIntervalTime] = useState(500000000000)
    const isEn = useIsEn()

    useInterval(() => {
        setIdx(idx + 1) 
    }, intervalTime)

    return <LazyLoadComponent afterLoad={setUnLock} placeholder={<div style={{height: '500px'}}></div>}>
        <div className={classNames({[css.block]: true, [css['is-en']]: isEn})}>
            {children}
            <h3>{t(title)}</h3>
            <div className={css.description}>{t(desc)}</div>
            <h6 className={css['part-title']}>{t(items[realIdx]?.title)}</h6>
            <div className={css['part-pic-wrap']}>
                <animated.div className={css['part-pic']} style={style} {...swipeHandler}>
                    <PicItem item={items[items.length - 1]} setWidthHandler={setItemWidth} setLock={setLock} setUnLock={setUnLock}/>
                    {items.map(item => <PicItem item={item} setLock={setLock} setUnLock={setUnLock}/>)}
                    <PicItem item={items[0]} setLock={setLock} setUnLock={setUnLock} />
                </animated.div>
            </div>
            <div className={css['part-description']}>{t(items[realIdx].msg)}</div>
            <div className={css['selector']}>
                <img src={prevBtn} alt="" className={css.prev} onClick={prev}/>
                <img src={nextBtn} alt="" className={css.next} onClick={next}/>
                <div className={css['dot-grp']}>
                    {
                        items.map((item, i) => <div className={classNames({[css.dot]: true, [css.active]: idx === i})}></div>)
                    }
                </div>
            </div>
            <div className={css.padding} />
        </div>
    </LazyLoadComponent>
}