import { ServiceBlock } from "./Block";
import pic1 from '@asset/0003-手机-服务中心/手机-服务-商超即时配送@2x.png'
import pic2 from '@asset/0003-手机-服务中心/手机-服务-门店库存共享@2x.png'
import pic3 from '@asset/0003-手机-服务中心/手机-服务-快递网点接驳@2x.png'
import pic4 from '@asset/0003-手机-服务中心/手机-服务-一对一急送@2x.png'
import pic5 from '@asset/0003-手机-服务中心/手机-服务-冷链运输@2x.png'
import { ServiceBanner } from "./ServiceBanner";

const items = [
    {title: '商超即时配送', desc: '我们帮助商超客户完成外卖订单的配送。通过白犀牛智能调度平台，动态调度无人车、自动规划车辆行驶路线和接驳地点，并调度接驳人员实现货品送到客户家中。', pic: pic1 },
    {title: '门店库存共享', desc: '我们使用无人车帮助零售客户实现多门店之间库存的动态调拨，从而使客户能够实现库存和实时销量的最佳匹配，提高潜在销售额的同时，降低潜在货损。', pic: pic2},
    {title: '快递网点接驳', desc: '我们为快递网点或转运中心提供不间断高频次接驳服务，从而可以实现快递网点到驿站的取送、网点之间错分件、急件的转运，从而让快递运输效率更高、成本更低。', pic: pic3},
    {title: '一对一急送', desc: '我们能够提供点对点即时送达服务，全程监控货品且无人参与，让时效更快、私密性更高。', pic: pic4},
    {title: '冷链运输', desc: '我们的车厢拥有自动控温功能，针对生鲜类即时商品可全程保证冷链运输。且支持模块化、个性化定制，同时可保持24小时不间断运营，满足全时段消费需求。', pic: pic5}
]

export const ServicePage = () => <>
    <ServiceBanner />
    {
        items.map(item => <ServiceBlock {...item}/>)
    }
</>