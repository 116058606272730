import { useTranslation } from 'react-i18next'
import css from './index.module.scss'
import mp4 from '@asset/0001-手机-首页-切图/手机-首页-顶部视频.mp4'
import { LoadingPic } from 'widget/loader'
import { animated, useSpring } from '@react-spring/web'
import { useRef, useState } from 'react'
import classNames from 'classnames'
import poster from './poster.png'
import playBtn from '@asset/2023手机端官网-icon-svg/播放按钮.svg'
import baiduBoxPic from './poster.png'

const isBaiduBox = navigator.userAgent.indexOf('baiduboxapp') > -1

export const Video = () => {
    const { t } = useTranslation()
    const [sloganStyle, sloganApi] = useSpring(() => ({
        from: { opacity: 0 },
        to: {opacity: 1},
        config: { duration: 300 },
        pause: true
    }))

    const [videoStyle, videoApi] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 800 },
        pause: true
    }))

    const [isLoading, setIsLoading] = useState(true)
    const videoRef = useRef<HTMLVideoElement>(null)
    
    const [isManualPlay, setIsManualPlay] = useState(false)


    return <div className={classNames({[css.video]: true, [css['manual-play']]: isManualPlay})}>
        <animated.div style={sloganStyle} className={css.slogan}>
        {t('以自动驾驶技术 让物流运输成本更低') }<br />{t('让人类出行更安全、更经济')}
        </animated.div>
        {isBaiduBox && <img className={css['baidu-box-pic']} src={baiduBoxPic} alt='' onLoad={() => setTimeout(() => sloganApi.resume(), 500)}/>}
        {isBaiduBox || <>
            <div className={css.mask} />
            <img
                src={playBtn}
                alt=""
                className={css['play-btn']} onClick={() => videoRef.current?.play().then(() => {
                setIsManualPlay(false)
            })}></img>
            <animated.video style={videoStyle} src={mp4}
                x5-video-player-fullscreen="true"
                x5-playsinline
                playsInline
                webkit-playsinline muted autoPlay loop
                ref={videoRef}
                poster={poster}
                onCanPlay={e => {
                    setIsLoading(false)
                    videoApi.resume()
                    videoRef.current?.play().catch(e => {
                        setIsManualPlay(true)
                    })
                    setTimeout(() => sloganApi.resume(), 500)
                }}/>
            {isLoading && <div className={css.loading}>
                <LoadingPic />
            </div>}
        </>}
        

    </div>
}