import css from './index.module.scss'
import picBg from '@asset/0005-手机-关于我们/关于我们头图@1x.png'
import picTeam from '@asset/0005-手机-关于我们/团队介绍@2x.png'
import picCar from '@asset/0005-手机-关于我们/手机-关于我们@2x.png'
import picCarLight from '@asset/2023手机端官网-icon-svg/car-points.svg'
import picPersist from '@asset/0005-手机-关于我们/坚持@2x.png'
import picSolidarity from '@asset/0005-手机-关于我们/团结@2x.png'
import { History } from './history'
import { Honor } from './Honor'
import { LazyImg } from 'widget/lazyImg'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from '@react-spring/web'

export const AboutUs = () => {
    const { t } = useTranslation()

    const [picStyle, picApi] = useSpring(() => ({
        from: { opacity: 0 },
        to: [
            { opacity: 1 },
            { opacity: 0 },
            { opacity: 1 },
            { opacity: 0 },
            { opacity: 1 }
        ],
        delay: 500
    }))

    const AnmiLazyImg = animated(LazyImg)

    return <div className={css['about-us']}>
        <div className={css.banner}>
            <img className={css.bg} src={picBg} alt="" />
            <h3>{t('关于我们')}</h3>
        </div>
        <div className={css['corp-introduce']}>
            <h6>{t('企业介绍')}</h6>
            <div className={css.desc}>
            {t('白犀牛是一家L4自动驾驶公司，始终专注于城市公开道路上的自动驾驶产品和服务。以无人配送为起点，致力于让物流运输成本更低，让人类出行更安全、更经济。')}
            </div>
            <div className={css['car-grp']}>
                <animated.div style={picStyle}>
                    <LazyImg src={picCarLight} alt="" className={css.light} afterLoad={() => picApi.resume()}/>
                </animated.div>
                <LazyImg src={picCar} alt="" className={css.car} />
            </div>
        </div>
        <div className={css['corp-culture']}>
            <h6>{t('团队介绍')}</h6>
            <div className={css.desc}>
            {t('我们的创始团队与核心骨干研发人员，均来自国内外顶级自动驾驶企业，是国内最早从事自动驾驶技术研发和商业化运营的团队之一。核心研发团队规模近百人，主要来自百度、阿里巴巴、滴滴、字节跳动等知名互联网公司以及自动驾驶头部企业，70%以上为软件和硬件研发工程师，主要毕业于清华大学、北京大学、中国科学技术大学、芝加哥大学、香港大学、中国科学院等国内外知名院校以及科研机构，具备过硬的自动驾驶技术研发和应用示范能力。')}
            </div>
            <LazyImg src={picTeam} alt=""/>
        </div>
        <div className={css['corp-culture-items']}>
            <h6>{t('坚持')}</h6>
            <div className={css.desc}>
            {t('我们崇尚坚持目标，专注于自动驾驶技术突破，在业务创新上保持坚定，推动自动驾驶技术更广泛的应用落地。')}
            <LazyImg src={picPersist} alt="" placeholder={<img src="" alt="" />} />
            </div>
            <h6>{t('团结')}</h6>
            <div className={css.desc}>
            {t('越是面对困难，我们越能紧密团结，相互合作、互相支持，充分发挥集体力量战胜困难。')}
            <LazyImg src={picSolidarity} alt="" placeholder={<img src="" alt="" />} />
            </div>
        </div>
        
        <History />
        <Honor />
    </div>
}