import css from './index.module.scss'
import bg from '@asset/0006-手机-联系我们/联系我们背景头图@2x.png'
import picPhone from '@asset/2023手机端官网-icon-svg/contact-phone.svg'
import picEmail from '@asset/2023手机端官网-icon-svg/contact-email.svg'
import picAddress from '@asset/2023手机端官网-icon-svg/contact-address.svg'
import picCar from '@asset/0006-手机-联系我们/联系我们小车@2x.png'
import { LazyImg } from 'widget/lazyImg'
import { useTranslation } from 'react-i18next'

export const Contact = () => {
    const { t } = useTranslation()
    return <div className={css['contact']}>
        <LazyImg src={bg} alt="" className={css.bg} />
        <h3>{t('联系我们')}</h3>
        <div>
            <div className={css['contact-info']}>
                <img src={picPhone} alt="" />
                <div className={css.type}>{t('联系电话')}</div>
                <div className={css.msg}>400-113-0423</div>
            </div>
            <div className={css['contact-info']}>
                <img src={picEmail} alt="" />
                <div className={css.type}>{t('合作邮箱')}</div>
                <div className={css.msg}>contacts@rino.ai</div>
            </div>
            <div className={css['contact-info']}>
                <img src={picAddress} alt="" />
                <div className={css.type}>{t('总部地址')}</div>
                <div className={css.msg}>{t('北京市 海淀区 花园东路11号 泰兴大厦 9层')}</div>
            </div>
        </div>
        <LazyImg className={css.car} src={picCar} alt="" delay={1000}/>
    </div>
}