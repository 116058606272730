import { LazyImg } from 'widget/lazyImg'
import css from './index.module.scss'
import { useTranslation } from 'react-i18next'

export const ServiceBlock: React.FC<{
    title: string,
    desc: string,
    pic: string
}> = ({title, desc, pic}) => {
    const { t } = useTranslation()
    return <div className={css['service-block']}>
        <h3>{t(title)}</h3>
        <div className={css.desc}>{t(desc)}</div>
        <LazyImg src={pic} alt=""/>
    </div>
}